<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title>{{ $t("keydetail.details") }}<v-spacer></v-spacer>
          <v-icon size="35">mdi-information</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 450px">
          <div>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs12 lg12>
                <v-subheader>{{ $t("keydetail.general") }}
                  <v-divider class="mx-4"></v-divider
                ></v-subheader>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs12 lg12 class="pl-4 pt-4">
                <v-icon x-large :color="seticon(fileresend.data_type)[1]" style="font-weight: 100" >{{ seticon(fileresend.data_type)[0] }}</v-icon>
              </v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs3 lg3 class="pl-4 pt-4"><p class="font-weight-bold">{{ $t("resendfile.filename") }} :</p></v-flex>
              <v-flex xs9 lg9 class="pl-4 pt-4"><p>{{ fileresend.filename }}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs3 lg3 class="pl-4"><p class="font-weight-bold">{{ $t("resendfile.document_no") }} :</p></v-flex>
              <v-flex xs9 lg9 class="pl-4"><p>{{ fileresend.document_no }}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs3 lg3 class="pl-4"><p class="font-weight-bold">{{ $t("resendfile.document_type") }} :</p></v-flex>
              <v-flex xs9 lg9 class="pl-4"><p>{{ fileresend.document_type }}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs3 lg3 class="pl-4"><p class="font-weight-bold">{{ $t("resendfile.data_type") }} :</p></v-flex>
              <v-flex xs9 lg9 class="pl-4"><p>{{ fileresend.data_type }}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs3 lg3 class="pl-4"><p class="font-weight-bold">{{ $t("resendfile.receiver_name") }}:</p></v-flex>
              <v-flex xs9 lg9 class="pl-4"><p>{{ fileresend.receiver_name }}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs3 lg3 class="pl-4"><p class="font-weight-bold">{{ $t("resendfile.sender_name") }} :</p></v-flex>
              <v-flex xs9 lg9 class="pl-4"><p>{{ fileresend.sender_name }}</p></v-flex>
            </v-layout>
            <v-layout xs12 lg12 row wrap>
              <v-flex xs3 lg3 class="pl-4"><p class="font-weight-bold">{{ $t("resendfile.cre_dtm") }} :</p></v-flex>
              <v-flex xs9 lg9 class="pl-4"><p>{{ fileresend.cre_dtm }}</p></v-flex>
            </v-layout>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('closedialog')" color="red" dark>{{ $t("resendfile.closefromdateresend") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show", "fileresend"],
  data: function () {
    return {};
  },
  computed: {
    shower: {
        get() {
        if (this.show === true) {
            console.log('fileresend',this.fileresend);
        }
        return this.show;
        },
    },
  },
  methods: {
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-question-outline", "black"];
      }
      return dataicon;
    },
  },
};
</script>